.modal-body.table-modal-body {
  flex-direction: column;
  margin-bottom: revert;
}
.modal-header.table-modal-header {
  background-color: #CED4DA;
  padding: 1em;
}
.table-modal-header h5 {
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.modal-body .btn-danger {
  background-color: #FE627E;
}
