.edit-row-block {
    justify-content: center;
    height: 100%;
}
.edit-form-card-block {
    width: 100%;
    height: calc(100vh - 60px);
}
.edit-form-card-block .card-body {
    overflow: auto;
    padding-top: 0;
}
.edit-form-card-block .table-title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
}
.pointer {
    cursor: pointer;
}
.edit-form-card-block .table-title .keyboard_return {
    font-size: 2rem;
    margin-right: 5px;
    padding-top: 6px;
}
.separator-block {
    font-weight: 600;
    font-size: 1.3em;
    padding: 10px 0 5px 1vw;
    border-bottom: 1px solid black;
    margin-top: 25px;
}
.edit-form-item {
    display: grid;
    position: relative;
    cursor: default;
    min-height: 30px;
    grid-gap: 1em;
    align-items: center;
    border-bottom: 1px solid #868e967e;
    grid-template-columns: 20% 50% 30%;
}
.edit-form-item .changelog-table {
    white-space: nowrap;
    max-height: 500px;
    overflow: auto;
    width: fit-content;
}
.edit-form-item .changelog-table thead {
    border-bottom: 2px solid grey;
}
.edit-form-item .changelog-table tbody tr {
    border-bottom: 1px solid grey;
}
.edit-form-item .changelog-table tbody tr td {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    padding: 5px;
}
.aligning-top {
    padding: 1em 0;
    align-items: flex-start;
}
.edit-form-item .lock-icon {
    vertical-align: top;
}
.edit-form-item i.material-icons {
    position: absolute;
    cursor: pointer;
}
.edit-form-item:hover > i.material-icons  {
    font-size: 1.5rem;
    color: inherit;
}
.edit-form-item select {
    height: 29px;
    background-color: transparent;
    padding-left: 7px;
}

.edit-form-item i {
    color: rgba(0, 0, 0, 0);
}
.edit-form-item .main-label {
    position: relative;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.edit-form-item .main-label .label-title {
    font-size: 1rem;
    text-transform: capitalize;
    
}
.edit-form-item input {
    padding-right: .5em;
    background-color: transparent;
}
.edit-form-item input:disabled {
    background-color: transparent;
    cursor: pointer;
}
.edit-form-item input:focus-visible {
    outline: none;
}

.edit-form-item .edit-form-input:hover {
    background-color: #cfeef8;
} 
.show-editor-icon {
    display: inherit;
    cursor: pointer;
    padding: 3px;
    background-color: transparent;
    width: min-content;
    margin-right: 10px;
}
.edit-form-input {
    border: none;
    width: 100%;
    font-size: 1rem;
    padding: 5px 0 0 10px;
}
.edit-form-input-blank-label {
    position: absolute;
    width: 50%;
    left: 20%;
    height: 100%;
    background-color: white;
    z-index: 100;
    cursor: pointer;
}
.edit-form-input-blank-label:hover {
    background-color: #cfeef8;
} 
.edit-form-input-label {
    position: absolute;
    left: 0px;
    top: 0;
    margin-bottom: 0;
    font-size: .8em;
}
.edit-form-input img {
    max-height: 50vh;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;
}
.edit-form-disabled-input {
    user-select : none;
    -moz-user-select : none;
    -webkit-user-select : none;
    color: gray;
}
.edit-form-item .scans {
    cursor: pointer;
    position: relative;
}
.edit-form-item .scans .set-pdf-image-size {
    max-height: 10vh;
}
.edit-form-item .scans .set-pdf-image-link-size {
    max-height: 3vh;
    position: absolute;
    right: 0;
    top: 0;
}
.edit-form-item .scans span:hover {
    cursor: pointer;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-default {
    cursor: default;
}
.edit-form-item .opened-image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1070;
}
.opened-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 2;
}
.scan-newtab-open-icon {
    transform: scale(1.5);
    margin: 10px 0 20px;
}
.opened-image-block .image-block-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #0000003d;;
    top: 0;
    left: 0;
    z-index: 2;
}
.opened-image-block .opened-image {
    max-height: 90vh;
    max-width: 90vw;
    z-index: 3;
}
.opened-image-block .scans-nav {
    position: absolute;
    top: 45% !important;
    z-index: 3;
    background-color: #ffffff3d;
    border-radius: 50%;
    font-size: 3rem !important;
    color: black ;
}
.opened-image-block .scan-title {
    padding: 10px 20px;
}
.opened-image-block .go-left-scan {
    left: 10% !important;
}
.opened-image-block .go-right-scan {
    right: 10% !important;
}
.edit-form-item .textarea-box {
    background-color: aquamarine;
    position: absolute;
    width: 50vw;
    border: 2px solid black;
    height: 20vh;
}
.edit-form-item .incorrect-value {
    color: red;
}
.table-title .edit-save-block {
    position: absolute;
    top: 16px;
    right: 193px;
    display: flex;
}
.table-title .divider {
    height: 30px;
    width: 1px;
    background-color: lightgrey;
}
.table-title .brief-header-user-data {
    display: flex;
    position: relative;
    margin-top: 7px;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 1em;
    font-size: 1.2em;
    margin-left: 1em;
}
.table-title .brief-header-user-data span {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.brief-header-user-data .header-user-data-reviews {
    position: absolute;
    right: -5em;
    bottom: -2px;
    background-color: red;
    padding: 3px 5px;
    border-radius: 15px;
    color: white;
}

.table-title .edit-save-block button {
    margin: 0 .5rem;
    height: 3rem;
    font-size: .9em;
    width: 100px;
    border-radius: 10px;
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    color: #5a6169;
    background-color: transparent;
}
.table-title .edit-save-block button:hover {
    background-color: #cfeef8;
}
.table-title .require-button {
    right: 80px;
    top: 17px;
}
.table-title .require-button button {
    height: 3rem;
    font-size: 1em;
}
.hidden-items {
    visibility: hidden;
}
.edit-form-card-body {
    overflow: scroll;
    max-height: calc(100vh - 134px);
    position: relative;
}
.loading-dots {
    font-size: 5rem;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    margin: 0 0 0 10px;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .commented-field {
    display: flex;
    position: relative;
    font-size: 0.8rem;   
    margin-right: 1.875rem;
    flex-direction: column;
  }
  .commented-field input {
    border-left: 1px solid grey;
    height: 90%;
    cursor: auto;
    font-size: 1rem;
    padding: 3px 2em 3px 3px;
  }
  .commented-field input:hover {
      background-color: #cfeef8;
  }
  .commented-field .deficient-bg {
      background-color: rgba(255, 176, 176, 0.5);
  }
  .commented-field .updated-bg {
    background-color: #EEEE9B;
}
  .comment-delete-btn {
    position: absolute;
    top: 1px;
    border: none;
    right: 1px;
    height: 93%;
    width: 29.7px;    
    background-color: white;
    border-radius: 5px;
  }
  .comment-delete-btn:hover {
    background-color: #cfeef8;
  }
  .comment-delete-btn img {
      height: 18px;
      width: 17px;
      transition: .4s linear;
  }
  .commented-field .hovered-remove-image {
    transform: rotate(180deg);
  }
  .commented-field .remove-comment-line-off {
    position: absolute;
    left: 5px;
    width: 0%;
    top: 45%;
    height: 2px;
    border-radius: 5px;
    background-color: transparent;
    transition: .4s ease-in-out;
  }
  .commented-field .remove-comment-line-on {
    transition: .4s ease-in-out;
    width: 83%;
    position: absolute;
    top: 45%;
    left: 5px;
    border-radius: 5px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.793);
  }