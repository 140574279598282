body {
}

.table-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-title-right {
    display: flex;
}

.main-sidebar {z-index: 1040;}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

 input[type="date"]::-webkit-clear-button {
     z-index: 1;
 }
