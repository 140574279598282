.modal-header.table-modal-header, .table-modal-wrapper {
    padding: 10px 20px;
}

.table-modal-wrapper {
    padding-bottom: 20px;
    font-family: 'Montserrat';
}

.table-modal-wrapper .modal-header.table-modal-header {
    margin-bottom: 10px;
}

.table-modal-wrapper .modal-body.table-modal-body, .modal-footer.table-modal-footer {
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.table-modal-wrapper .modal-footer.table-modal-footer {
    padding-top: 20px;
}

.table-modal-select {
    flex-basis: 45%;
}

.table-modal-input {
    display: flex;
    align-items: center;
    flex-basis: 45%;
}

.table-modal-input-label {
    color: #495057;
    margin-right: 3px;
}

.table-modal-footer .btn {
    min-width: 100px;
    flex-basis: 45%;
    margin: 0;
}

.table-modal-header-link {
    display: flex;
    flex-wrap: wrap;
}

.table-modal-header .header-user-name {
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal-image{
  max-height: 100%;
  max-width: 100%;
}

div.modal {
  overflow-y: scroll;
}
