.column-editor-block {
    display: flex;
    flex-direction: column;
    padding: 2em;
}
.column-editor-block .column-editor-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #868e967e;
}
.column-editor-block .title-row {
    padding-right: 0;
}
.column-editor-block .title-row {
    text-transform: capitalize;
    font-size: 1.3rem;
    width: auto;
}

.sort .button {
    color:rgb(241, 240, 240);
    padding: 5px;
    border: 1px solid rgb(243, 243, 243);
    border-radius: 10px;
    margin-inline: 1px;
    cursor: pointer;
}

.sort .button:hover {
    background-color: darkgrey;
}

.column-editor-row .custom-control {
    padding-left: 3rem !important;
}
.custom-toggle .custom-control-label::before {
    top: 2px !important;
}
.custom-toggle .custom-control-label::after {
    top: 0.35rem !important;
}
.edit-save-block {
    position: absolute;
    top: 25px;
    right: 0;
}
.table-title-column-editor {
    display: flex;
    align-items: center;
}
.table-title-column-editor .edit-save-block button {
    margin: 0 .7rem;
    height: 2rem;
    width: 100px;
    border-radius: 10px;
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1rem;
    color: #5a6169;
    background-color: transparent;
}
.table-title-column-editor i {
    font-size: 2rem !important;
    transform: rotateX(0.5turn);
    align-self: flex-start;
}
.column-editor-row .id {
    width: 40%;
    margin-right: -1rem;
}
.column-editor-row .title {
    width: 40%;
    margin-right: -1rem;
}
.column-editor-row .display {
    width: 10%;
    margin-right: -1rem;
}
.column-editor-row .sort {
    width: 10%;
    margin-right: -1rem;
}
.column-editor-row .edit-column-item {
    border: none;
    width: 40%;
    font-size: 1rem;
    padding: 5px 0 0 10px;
    background-color: transparent;
}

.edit-column-disabled-item {
    user-select : none;
    -moz-user-select : none;
    -webkit-user-select : none;
    color: gray;
}
.cursor-pointer {
    cursor: pointer;
}
.hidden-items {
    visibility: hidden;
}
.column-editor-row:hover {
    background-color: rgb(241, 240, 240);
}

.column-editor-row:hover .edit-column-item {
    background-color: rgb(247, 247, 247);
}
