.dataset-toolbox {
    display: flex;
}

.dataset-toolbox div {
    margin: 2px .5rem 0;
    font-size: x-large;
    align-items:baseline;

}

.dataset-toolbox .material-icons:hover {
    color: royalblue;
    cursor: pointer;
}

.dataset-toolbox .material-icons {
    color: #5a6169 ;
    transform: scale(1.7);
}
.dataset-toolbox .refresh {
    transform: scale(1.15);
}
.dataset-toolbox .refresh i {
    top: 3px;
}
.dataset-toolbox .bookmark {
    transform: scale(1.05);
}