.chat-popup-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 12;
}
.chat-popup-wrapper .popup-black-area {
    background-color: rgba(0, 0, 0, 0.267);
    position: absolute;
    height: 100%;
    width: 100%;
}
.chat-popup-wrapper  .chat-popup-area {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;
    width: 50vw;
    font-size: 12px;
    max-width: 400px;
    max-height: 300px;
    text-align: left;
}
.chat-popup-area .chat-popup-title {
    background-color: #CED4DA;
    font-weight: bold;
    color: black;
    padding: 1em;
}
.chat-popup-area .popup-message {
    padding: 1em;
    font-weight: 500;
    text-align: center;
  }
.chat-popup-area  .chat-popup-buttons {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 1em;
    padding: 1em;
    padding-top: 0;
}
.chat-popup-buttons button {
    cursor: pointer;
    width: fit-content;
    padding: .7em 1em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-family: "Fira Sans", sans-serif;
    font-size: 12px;
    color: black;
    opacity: .8;
    transition: 0.3s ease-in-out;
    min-width: 100px;
}
.chat-popup-buttons button:disabled {
    background-color: grey;
    color: darkgrey;
}
.chat-popup-buttons button:focus {
    box-shadow: none;
}
.chat-popup-buttons button:hover {
    opacity: 1;
}
.chat-popup-buttons .yes-button {
    background-color: #90D0AA;
    color: white;
}
.chat-popup-buttons .yes-remove-button {
    background-color: #FE627E;
    color: white;
}
.chat-popup-buttons .no-button {
    background-color: white;
    border: 1px solid black;
}