.opened-dialog-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding-right: 186px;
    top: 61px;
    height: calc(100vh - 62px);
    width: 100vw;
    z-index: 1;
}
@media (max-width: 992px) {
    .opened-dialog-block {
        padding-right: 228.5px;
    }
}
@media (max-width: 768px) {
    .opened-dialog-block {
        padding-right: 0;
    }
}

.opened-dialog-block .dialog-block-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #0000003d;;
    top: 0;
    left: 0;
    z-index: 2;
}
.opened-dialog-block .dialog-popup {
    margin: auto;
    z-index: 3;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.dialog-popup .button-block {
    padding-bottom: 2rem;
    z-index: 3;
}
.dialog-popup .button-block .popup-buttons-in-block {
    padding-left: 2rem;
}

.dialog-popup .button-block header {
    background-color: lightgrey;
    padding: 1rem;
    font-size: 1.1em;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.dialog-popup .button-block .popup-button-text {
    padding: 1rem 2rem;
}
.dialog-popup .button-block button {
    margin: 0 .5rem;
    height: 2rem;
    font-size: .9em;
    width: 100px;
    border-radius: 10px;
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    color: #5a6169;
    background-color: transparent;
}
.dialog-popup .button-block button:hover {
    background-color: #cfeef8;
}
