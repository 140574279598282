.request-header-box, .request-action-box {
    list-style: none;
    padding: 0;
    margin: 0;
}

.request-header-box .applied-value {
  cursor: pointer;
}

.request-header-wrapper {
    background-color: #fff;
}

.request-header-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1em;
    margin-bottom: 10px;
}

.request-header-item {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.request-header-item .request-process img,
.request-header-item .request-start img,
.request-header-item .no-assignee img {
  cursor: pointer;
}

/* .request-header-item:hover .request-start {
    display: block !important;
} */

/* .request-header-item:hover .no-assignee {
    display: none !important;
} */

.request-header-key-column {
    font-weight: 700;
    margin-right: 30px;
    min-width: 110px;
}

.request-header-value-column {
    font-weight: 400;
}

.request-action-box {
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
}

.request-action-item {
    margin-right: 20px;
    padding: 5px 10px;
    border: 1px solid #000;
    border-radius: 8px;
    cursor: pointer;
    color: #000;
    font-size: 12px;
    margin-bottom: 15px;
}

.request-header-info {
    display: flex;
    justify-content: space-between;
}

.request-header-info-left, .request-header-info-right {
    display: flex;
}

.request-header-info-left {
}

.request-header-info-right {
}
