.details-menu-block .trader-edit-info-block {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 0 4em;
}
.trader-edit-info-block .info-box {
    display: flex;
    flex-direction: column;
}
.info-box .info-item {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}
.trader-select-block {
    font-size: 12px;
}
.trader-select-block .not-match-block {
    color: red;
}
.trader-select-block .received-by-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
}
.trader-select-block .received-by-list {
    width: 35%;
}
.trader-select-block .received-by-list select {
    border: 1px solid black;
    border-radius: 8px;
}
.received-by-list .form-control {
    color: grey;
}
.format-options-block {
    display: flex;
    overflow: hidden;
}
.format-options-block:hover {
    color: blue;
    cursor:pointer;
}
.received-by-list select {
    padding: 8px;
}