.main-image-logo {
    max-width: 15%;
    margin-left: 5px;
}
@media (min-width: 991px) and (max-width: 1200px) {
    .main-logo-title {
        font-size: .75em;
    }
}
.main-logo-block {
    cursor: pointer;
    align-items: center;
}
