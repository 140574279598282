.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 25px;
  padding: 5px;
  margin-left: 25px;
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.main-sidebar .nav--no-borders .nav-item .nav-link {
  display: flex;
  align-items: center;
}

.main-sidebar .sidebar-sub-icons, .main-sidebar .sidebar-icons {
  display: flex;
  align-items: center;
  max-height: 15px;
  top: 0 !important;
}

.main-sidebar .sidebar-sub-icons {
  padding-left: 20px;
}

.main-sidebar .sidebar-icons {
  padding-left: 5px;
}

.main-sidebar .nav-wrapper .nav .nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: auto;
}

.main-sidebar .nav-wrapper .nav .nav-item .sidebar-badge {
  background-color: #a1a1a1;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 24px;
  width: 24px;
}

.main-sidebar .nav-wrapper .sidebar-badge-box {
  display: flex;
}
