.applied-modal-container input::-webkit-outer-spin-button,
.applied-modal-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.applied-modal-container input[type=number] {
  -moz-appearance: textfield;
}

.applied-modal-container button.btn {
  border-radius: 8px;
  border: 1px solid #000;
  padding: 0.7em 2em;
  color: #000;
}

.applied-modal-container .modal-content {
  border-radius: 0;
}

.applied-modal-container .modal-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.applied-modal-container .modal-input .modal-input-label {
  padding-right: 5%;
}

.applied-modal-container .modal-input input {
  width: 50%;
  text-align: right;
}

.applied-modal-container .modal-input .modal-input-field--error {
  color: #dc3545;
}

.applied-modal-container .modal-footer {
  display: flex;
  justify-content: space-between;
}

.applied-modal-container .modal-header {
  background-color: rgb(206 212 219);
  padding: 0.7em 1em;
  border-radius: 0;
}

.applied-modal-container .modal-header h5 {
  font-size: 13px;
  color: #212529;
  font-weight: 800;
}

.applied-modal-container .modal-error {
  text-align: center;
  padding-top: 10px;
  margin-bottom: -15px;
  color: #dc3545;
}