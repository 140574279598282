.pages-navigation {
    display: flex;
    align-items: center;
}
.pages-navigation h4 {
    margin-bottom: 0;
    font-size: 14px;
}
.pages-navigation h4.disabled{
    pointer-events: none;
    cursor: none;
}
.line-height-arrows {
    line-height: 1.5rem;
}

.line-height-arrows i {
    color: grey;
    font-size: 25px !important;
}

.pagination-select-dropdown {
    border: 1px solid grey;
}