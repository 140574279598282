@use "../utilities/colors.scss" as c;
 .marketdata-wrapper {
	 font-size: 1rem;
    }
    .marketdata-header {
        font-weight: bold;
        text-align: initial;
        font-size: 30px;
   }
    .marketdata-bundle {
        display: flex;
        align-items: center;
        gap: 1em;
   }
    .marketdata-bundle .bundle-text {
        font-weight: bold;
        font-size: 16px;
   }
    .marketdata-bundle .marketdata-button-group {
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid black;
        border-radius: 8px;
   }
    .marketdata-bundle .marketdata-button-group .bundle-button {
        background-color: white;
        padding: 6px 12px 6px 12px;
        font-size: 12px;
        width: 101px;
        border: 1.25px solid transparent;
        border-radius: 8px;
        position: relative;
   }
    .marketdata-bundle .marketdata-button-group .bundle-button hr {
        position: absolute;
        height: 50%;
        background-color: grey;
        width: 1px;
        top: 50%;
        right: -2.25px;
        transform: translate(0, -50%);
        margin: 0;
        z-index: 2;
   }
    .marketdata-bundle .marketdata-button-group .bundle-button:hover {
        border: 1.25px solid black;
        z-index: 3;
   }
    .marketdata-bundle .marketdata-button-group .bundle-button:hover hr {
        display: none;
   }
    .marketdata-bundle .marketdata-button-group .last-button hr {
        display: none;
   }
    .marketdata-bundle .marketdata-button-group .checked-button {
        background-color: #90D0AA;
        font-weight: bold;
        border: 1px solid black;
        border: 1.25px solid black;
        z-index: 3;
   }
    .marketdata-bundle .marketdata-button-group .checked-button hr {
        display: none;
   }
    .marketdata-bundle .marketdata-button-group .primary-bundle {
        z-index: 3;
        background-color: #90D0AA;
        font-weight: bold;
        border: 1px solid black;
        border: 1.25px solid black;
   }
    .marketdata-bundle .marketdata-button-group .primary-bundle hr {
        display: none;
   }
    .marketdata-bundle .marketdata-button-group .waiting-bundle {
        z-index: 3;
        border: 1px solid black;
        border: 1.25px solid black;
        background-color: #FFFF0033;
   }
    .marketdata-bundle .marketdata-button-group .waiting-bundle hr {
        display: none;
   }
    .marketdata-main-block {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: fit-content;
        gap: 1em;
        margin-top: 1em;
   }
    .marketdata-main-block .marketdata-item-block {
        max-width: 400px;
        min-width: 250px;
   }
    .marketdata-main-block .marketdata-item-block .market-title {
        background-color: #90D0AA;
        font-size: 16px;
        padding: 10px 16px;
        border-radius: 8px;
        margin-bottom: 0.5em;
        line-height: 12px;
   }
    .marketdata-main-block .marketdata-item-block .market-body {
        font-size: 12px;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 11px;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item {
        display: flex;
        margin-right: 16px;
        position: relative;
        align-items: center;
        white-space: nowrap;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item input {
        margin-right: 0.7em;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item input:checked ~ .market-checkmark {
        background-color: transparent;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item input:checked ~ .market-checkmark::after {
        display: block;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item input:hover ~ .market-checkmark {
        background-color: #90d0aa7a;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item input:disabled ~ .market-checkmark {
        background-color: #90d0aa7a;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item .market-checkmark {
        z-index: 1;
        position: absolute;
        cursor: pointer;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border: 1px solid black;
        border-radius: 4px;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item .market-checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 2.3px;
        width: 5px;
        height: 8px;
        border: 1px solid black;
        border-width: 0 2px 2px 0;
        border-radius: 1px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item .added-item {
        background-color: #FFFF0033;
        color: #008000;
   }
    .marketdata-main-block .marketdata-item-block .market-body .market-item .removed-item {
        background-color: #FFFF0033;
        color: #E42424;
        text-decoration: line-through;
   }
    .marketdata-main-block .marketdata-item-block .market-body .added-item {
        background-color: #FFFF0033;
        color: #008000;
   }
    .marketdata-main-block .marketdata-item-block .market-body .removed-item {
        background-color: #FFFF0033;
        color: #E42424;
        text-decoration: line-through;
   }
    .marketdata-main-block hr {
        margin: 0;
        margin-bottom: 1em;
   }
    .confirmation-block {
        border-top: 1px solid rgba(0,0,0,.1);
        text-align: left;
        background-color: #FFFF0033;
        width: fit-content;
        padding: 1em;
        font-size: 12px;
        max-width: 600px;
        margin-bottom: 1em;
   }
    .confirmation-block .effective-date-block .monthly-fee-text {
        margin-top: 0.25em;
        text-decoration: underline;
   }
    .confirmation-block .effective-date-block .previously-text {
        margin-left: 1em;
        color: #E42424;
   }
   .confirmation-block .effective-date-block input:focus-visible {
    outline:none !important;
   }
    .confirmation-block ul {
        width: fit-content;
   }
    .confirmation-block ul li .monthly-cost-item {
        display: flex;
        justify-content: space-between;
        gap: 1em;
   }
    .confirmation-block input[type="date" i] {
        border: 1px solid black;
        border-radius: 5px;
        padding: 3px 5px;
   }
    .confirmation-block .confirmation-buttons {
        display: flex;
        gap: 1em;
        margin-top: 2em;
   }
    .confirmation-block .confirmation-buttons button {
        height: 32px;
        min-width: 94px;
        left: 110px;
        top: 0px;
        border-radius: 8px;
        padding: 9px 16px 9px 16px;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid black;
        background-color: #fff;
        transition: 0.3s ease-in-out;
   }
    .confirmation-block .confirmation-buttons button:hover {
        border: 1px solid transparent;
   }
    .confirmation-block .confirmation-buttons .yes-button {
        color: white;
        font-weight: 600;
        background-color: #90D0AA;
        border: none;
   }
    .confirmation-block .confirmation-buttons .yes-button:hover {
        border: 1px solid black;
   }
    .confirmation-block .confirmation-buttons .yes-button:disabled {
        background-color: #90d0aa7a;
   }
    .confirmation-block .confirmation-buttons .cancel-requet-button {
        color: white;
        font-weight: 600;
        background-color: #FE617E;
        border: 1px solid transparent;
   }
    .confirmation-block .confirmation-buttons .cancel-requet-button img {
        margin-right: 5px;
   }
    .confirmation-block .confirmation-buttons .cancel-requet-button:hover {
        border: 1px solid black;
   }
    .market-total {
        text-align: left;
        font-weight: bold;
   }
   .marketdata-wrapper hr {
    opacity: 1;
    margin-bottom: 0 !important;
   }
    .bold-text {
        font-weight: bold;
   }
    .ml-4 {
        margin-left: 1em;
   }
.no-markets-popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25%;
    left: 25%;
    max-width: 40%;
    font-size: 12px;
    z-index: 4;
}
.no-markets-black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.151);
    z-index: 1;
}
.no-markets-popup .no-markets-header {
    background-color: #CED4DA;
    color: black;
    font-weight: bold;
    padding: 1em;
    z-index: 4;
}
.no-markets-popup .no-markets-body {
    padding: 1em;
    background-color: white;
    z-index: 4;
}
.no-markets-popup .no-markets-buttons {
    padding: 1em;
    z-index: 4;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content:center;
    align-items:center;
}