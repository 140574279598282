.chat-upload-button-block {
}
.chat-upload-button-block button {
    background-color: transparent;
    border: none;
    color: blue;
    text-decoration: underline;
    transition: .3s ease-in-out;
}
.chat-upload-button-block button:hover {
    text-decoration: none;
}