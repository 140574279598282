.chat-wrapper {
    position: relative;
    padding: 1em;
}
.chat-window-back-button {
    position: absolute;
    top: .5em;
    left: 1em;
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: lightblue;
    transition: .3s ease-in-out;
}
.chat-window-back-button:hover {
    transform: scale(.9);
}
.chat-wrapper .chat-header {
    position: relative;
    font-size: 12px;
    margin-top: 2em;
}
.chat-header .chat-header-button {
    position: absolute;
    right: 0;
    bottom: 0
}
.main-header-button {
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    padding: .7em 1em;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-family: "Fira Sans", sans-serif;
    font-size: 12px;
    color: black;
    opacity: .8;
    transition: 0.3s ease-in-out;
    min-width: 100px;
}

.chat-header-button .main-header-button:disabled, .chat-header-button .resolve-type:disabled, .chat-header-button .cancel-type:disabled {
    background-color: lightgrey;
}
.chat-header-button .main-header-button:hover, .chat-header-button .resolve-type:hover, .chat-header-button .cancel-type:hover {
    opacity: 1;
}
.chat-header .chat-header-columns {
    display: flex;
    gap: 2em;
}
.chat-header .first-column, .chat-header .second-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
}
.chat-header .first-column {
    font-weight: bold;
}
.chat-message-history {
    background-color: #90d0aa30;
    border: 1px solid rgba(0, 0, 0, 0.445);
    border-radius: 8px;
    font-size: 12px;
    max-height: calc(100vh - 400px);;
    overflow: auto;
}
.chat-message-history .spinner {
    position: absolute;
    top: 38%;
    left: 50%;
    color: #008000;
}
.chat-message-history-dowloading {
    position: relative;
    margin: 1em 0 3em;
}
.chat-message-history-dowloading  .spinner {
    position: absolute;
    top: 0;
    left: 50%;
    color: #90D0AA;
}
.downloading-attach {
    opacity: 0.8;
    z-index: -1;
}
.chat-wrapper .chat-message {
    padding: 1em 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.chat-message-preview {
    position: absolute;
    padding: 1em;
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    max-width: 90%;
    height: 90%;
}
.chat-message-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.chat-preview-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.406);
}
.chat-message .chat-message-upload-attachments {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: blue;
    text-decoration: underline;
}
.chat-message-attachments {
    display: flex;
    flex-wrap: wrap;
    gap: .3em;
    width: 100%;
    padding-right: 125px;
}
.chat-message-attachments-uploading {
    display: flex;
    flex-wrap: wrap;
    gap: .3em;
    width: 100%;
    padding-right: 125px;
    color: #90D0AA;
    text-decoration: none;
}
.chat-message-attachments-uploading .spinner {
    width: 1rem;
    height: 1rem;
}
.chat-message-attachments-uploading span {
    color: black;
}
.chat-message-attachments .attachment-item {
    cursor: pointer;
}
.chat-message-upload-attachments .remove-attach-icon {
    font-weight: bold;
    color: red;
    margin-left: .5em;
    cursor: pointer;
}
.chat-upload-button-block {
    width: fit-content;
}
.chat-message .chat-message-history {
    padding: 1em;
    width: 100%;
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.chat-message-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    margin-top: 1em;
    width: 65%;
    border: 1px solid rgba(0, 0, 0, 0.445);
    border-radius: 8px;
    font-size: 12px;
}
.chat-message-input .message-type-info {
    text-transform: uppercase;
    opacity: .5;
}
.chat-message-input textarea {
    width: 100%;
    border: none;
    margin-bottom: 2em;
}
.chat-message-attaches {
    display: flex;
    flex-wrap: wrap;
    color: blue;
}
.chat-message-button-types {
    display: flex;
    margin-top: 1em;
    gap: 1em;
}
.chat-message-attaches .chat-message-attach {
    text-decoration: underline;
    cursor: pointer;
}
.chat-message-input textarea:focus-visible {
    outline-color: transparent;
}
.chat-message-button {
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    padding: .5em;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    font-family: "Fira Sans", sans-serif;
    font-size: .8rem;
    color: white;
    opacity: .8;
    transition: 0.3s ease-in-out;
    background-color: #90D0AA;
    min-width: 100px;
}
.chat-message-send-button {
  position: absolute;
  bottom: 1em;
  right: 1em;
}
.chat-message-button:disabled {
    background-color: lightgrey;
}
.chat-message-button:hover {
    opacity: 1;
}
.chat-message-history .chat-message-item {
    position: relative;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 65%;
}
.chat-message-history .chat-message-item p {
    position: relative;
    margin: 0;
    padding: 1em;
    text-align: left;
    word-break: break-all;
    white-space: pre-wrap;
    border-radius: 8px;
    background-color: #90D0AA;
    border: 1px solid rgba(0, 0, 0, 0.445);
}
.chat-message-item p .chat-message-avatar {
    position: absolute;
    left: -37px;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-message-item .chat-message-avatar-full {
    position: absolute;
    right: 103%;
    width: fit-content;
    border: 1px solid black;
    top: 36px;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-message-history .chat-message-item .greyed {
    background-color: #8E857C;
}
.chat-message-history .right-align {
    align-self: flex-end;
    padding-right: 15%;
}
.chat-message-history .right-align p {
    border: none;
}
.chat-message-history .left-align {
    align-self: flex-start;
    align-items: flex-start;
    margin-left: 15%;
}
.chat-message-item .incoming-type {
    background-color: white !important;
}
.chat-message-item .incoming-admin-type {
    background-color: #FFF9E8 !important;
}
.resolve-type {
    background-color: #90D0AA;
    color: white;
}
.resolve-type:disabled {
    background-color: lightgrey;
}
.cancel-type {
    background-color: white;
    border: 1px solid black;
    color: black;
}
.internal-style {
    background-color: #FFF9E8;
    color: black;
    border: 1px solid black;
}
.chat-message-date {
    color: #484964;
}
.cursor-help {
    cursor: help;
}