span.search_pattern, th.search_pattern {
  background-color: yellow;
  font-size:110%;
}

button.search_cross:hover {
  background-color: lightgrey;
}

input.search_empty{
  background-image: linear-gradient(to right, rgb(255, 247, 247), white);
}

input.search_found{
  background-image: linear-gradient(to right, rgb(243, 255, 246), white);
}

div.navbar-search-label {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  padding-left: 15px;
}

div.navbar-search-label .badge {
  font-size: 1.2rem;
  padding: 5px 10px;
}
