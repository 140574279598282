.toaster {
  position: fixed;
  right: 0;
  z-index: 10000;
  top: 0px;
  display: flex;
  height: unset !important;
  width: unset !important;
  flex-direction: column;
  padding: 10px;
}

.toaster .toast{
  width: 40vw;
  min-width: 250px;

  border: 1px solid rgba(0,0,0,.3);
  opacity: 1;
  flex-basis: revert;
}


.toaster .toast .toast-header i{
  margin: 0 10px 0 0;
  font-size: xx-large;
}

.toaster .toast.info {
  background-color: #79f59c;
}

.toaster .toast.warning {
  background-color: #d5736a;
}


.toaster .toast.report {
  background-color: #fe2a17;
}

.toaster .toast.report .toast-header i{
  color: red;
}

.toaster .toast.info .toast-header i{
  color: #3899c2;
}

.toaster .toast.warning .toast-header i{
  color: orange;
}
