.dropdown-menu {
    right: 0;
    left: auto !important;
}
.dropdown-block .btn-primary {
  background-color: transparent !important;
}

.dropdown-block .btn-primary {
  all: unset
}
.dropdown-block .btn-primary:hover {
    all: unset;
}
.dropdown-block .btn-primary:active{
    all: unset;
}
.dropdown-block  .btn {
  all: unset
}
.dropdown-block .btn:hover {
    all: unset;
}
.dropdown-block .btn:active{
    all: unset;
}
.dropdown-block .dropdown-toggle::after {
    content: unset;
}