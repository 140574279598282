.card-header {
  border-radius: 0 !important;
}

.card {
  border: 0 !important;
  height: 100%;
}

.card-header .pag-left-arrow,
.pag-right-arrow {
  margin: 0 10px;
  cursor: pointer;
  transition: .2s ease-in;
}

.card-header .pag-left-arrow:hover,
.pag-right-arrow:hover {
  transform: scale(1.3);
  color: #007bff;
}

.tables-card-body {
  overflow: auto;
  max-height: calc(100vh - 130px);
  position: relative;
}

.table {
  cursor: pointer;
  user-select: text;
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table thead tr th {
  border: 2px solid #EEEEF2;
}

.table thead tr th .hover-sorting-icon {
  opacity: 0;
}

.table thead tr th:hover .hover-sorting-icon,
.table thead tr th:focus .hover-sorting-icon {
  opacity: 1;
}

.table thead tr th .sorting-title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.table tbody tr td {
  border: 1px solid #EEEEF2;
}

.table tbody tr:nth-child(2n) {
  background-color: rgb(0 123 255 / 10%);
}

.table thead tr th.center {
  text-align: center;
}

.table thead tr th.right {
  text-align: right;
}

.table-row {
  max-width: 350px;
}

.table-row.selected {
  background-color: red;
}

.show-editor {
  display: none;
  width: 20px;
}

div.show-index {
  padding-left: 5px;
  display: flex;
  justify-content: center;
}

.apps-table tr:hover div.show-index {
  display: none;
  transition: 0.1s;
  color: darkgrey;
}

.apps-table tr:hover .show-editor {
  display: inherit;
  cursor: pointer;
  padding: 3px;
  border: 0.5px solid black;
  border-radius: 40%;
  background-color: #BCBCBC;
}

tbody tr:hover {
  background-color: #bebebe !important;
  transition: 0.2s;
}

.object-reference {
  color: #007bff;
}

.main-content-container .table-header-row {
  position: sticky;
  top: -2px;
  z-index: 2;
}

.chosen-header {
  background-color: yellow;
}

.small-card {
  height: calc(100vh - 60px);
}

.report-submit-box {
  display: flex;
  align-items: center;
  margin-left: 10%;
}

.new-accounts-box {
  display: flex;
  align-items: center;
  margin-left: 3%;
  flex-grow: 1;
  text-align: right;
  justify-content: flex-end;
}

.new-accounts-box button.btn {
  color: #5a6169;
  font-weight: 600;
  border-radius: 8px;
  border-width: 2px;
  border-color: #5a6169;
}

.new-accounts-info {
  margin-right: 20px;
}

.new-accounts-box .material-icons {
  margin-left: 2px;
  padding-bottom: 4px;
  font-size: 30px;
  vertical-align: middle;
}

.report-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  max-height: 25px;
  padding: 5px;
  margin-left: 10px;
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
}

.table-header-title {
  position: relative;
}

.report-datasetToolbox {
  position: relative;
  top: 0;
  right: 0;
  margin-left: 10px;
}

.tables-card-body .table-market-field {
  position: relative;
  white-space: break-spaces;
  padding-left: 15px;
}

.tables-card-body .table-market-field .edit-market-icon {
  position: absolute;
  top: -15px;
  right: -15px;
  transform: scale(.8);
  color: grey;
  transition: .3s ease-in-out;
}

.tables-card-body .table-market-field .edit-market-icon:hover {
  transform: scale(1);
}

.tables-card-body .table-market-field.addMarkets {
  color: #07A446;
  margin-bottom: 10px;
}

.tables-card-body .table-market-field.removeMarkets {
  color: #CC2F0D;
}

.tables-card-body .table-market-field.addMarkets:before {
  content: '+';
}

.tables-card-body .table-market-field.removeMarkets:before {
  content: '-';
}

.tables-card-body .table-market-field.addMarkets:before,
.tables-card-body .table-market-field.removeMarkets:before {
  position: absolute;
  left: 0;
}

.tables-card-body .table-market-box {
  display: flex;
  justify-content: space-between;
}

.tables-card-body .table-market-box .table-market-pencil {
  cursor: pointer;
  align-self: flex-start;
  font-size: 16px;
  color: #495057;
  padding: 5px;
  transition: color 0.3s;
}

.tables-card-body .table-market-box .table-market-pencil:hover {
  color: #000;
}

.tables-card-body .table-dueDate-field {
  color: #CC2F0D;
}

.tables-card-body.request-table tr td:first-child {
  position: relative;
}

.tables-card-body.request-table tr td:first-child:before {
  content: '';
  transition: 0.2s;
}

.tables-card-body .table-dueDate-field.immediately {
  &:before {
    content: '';
    background-color: #FF8080;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.tables-card-body .table-dueDate-field.immediately span {
  color: #fff;
  position: relative;
  z-index: 2;
}

.tables-card-body.request-table tbody tr:hover td:first-child:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
}

.tables-card-body.request-table tbody tr:hover .show-editor {
  display: none;
}

.tables-card-body.request-table tbody tr:hover div.show-index {
  display: flex;
  color: #212529
}

.tables-card-body.request-table tbody tr.highlight {
  background-color: #b3b7a4;
}

.tables-card-body.request-table tbody tr.completed {
  opacity: 0.4;
}

.tables-card-body.request-table tbody tr.completed:hover {
  opacity: 1;
}

.tables-card-body.request-table tbody tr div.request-start img,
.tables-card-body.request-table tbody tr div.request-process img {
  height: 20px;
}

.tables-card-body.request-table tbody tr div.request-process>div:first-child {
  margin-right: 10px;
}

.tables-card-body.request-table tbody tr:not(.hasInProgress):hover div.request-start {
  display: flex !important;
}

.tables-card-body.request-table tbody tr:not(.hasInProgress):hover div.no-assignee {
  display: none !important;
}

.table-index {
  display: flex;
  justify-content: center;
}

.textarea-comment {
  min-height: 30px;
  max-height: 125px;
  resize: none;
  padding-right: 30px;
}

.checkbox-cell {
  user-select: none;
}
