.dataset-tabs-wrapper {
    position: relative;
}

.dataset-tabs-wrapper .table-title.request-table {
    padding-bottom: 53px;
}

.dataset-tabs-wrapper .request-table > .btn {
    white-space: nowrap;
}

.dataset-tabs-wrapper .table-title.request-table > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.dataset-tabs-content {
    display: flex;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 99;
}

.dataset-tabs-content.hide {
    display: none;
}

.dataset-tabs-box {
    display: flex;
    padding: 0;
    margin-right: 40px;
    margin-bottom: 0;
}

.dataset-tabs-box:last-child {
    margin-right: 0;
}

.dataset-tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #0a0c0d;
    border-bottom-width: 0;
    border-radius: 10px 10px 0 0;
    padding: 4px 10px;
    min-width: 100px;
    width: 170px;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
}

.dataset-tab-item.active {
    color: white;
    font-weight: bold;
}

.immediately {
    border-color: #FF8080;
}

.immediately.active {
    background-color: #FF8080;
}

.pending, .pendingeod {
    border-color: #007BFF;
}

.pending.active, .pendingeod.active {
    background-color: #007BFF;
}


.today {
    border-color: #91D0AB;
}

.today.active {
    background-color: #91D0AB;
}

.all {

}

.all.active {
    background-color: #000;
}

.unresolved {
    border-color: #91D0AB;
}

.unresolved.active {
    background-color: #91D0AB;
}

.closed {

}

.closed.active {
    background-color: #000;
}

.incoming {
    border-color: #91D0AB;
}

.incoming.active {
    background-color: #91D0AB;
}

.followup {
    border-color: grey;
}

.followup.active {
    background-color: grey;
}

.dataset-tab-item.in-progress {
    border-color: #91D0AB;
}

.dataset-tab-item.in-progress.active {
    background-color: #91D0AB;
}

.dataset-tab-item.on-hold {
    border-color: #d0cf91;
}

.dataset-tab-item.on-hold.active {
    background-color: #d0cf91;
}
